<template>
  <div id="messageDialog">
    <audio
      muted="false"
      controls="controls"
      class="alarm"
      style="display: none"
    >
      <source src="../../assets/alarm.mp3" type="audio/mp3" />
    </audio>
    <initSocket @connectSuccess="getTalk" ref="socket" WS_URL="screen/" />
    <video-dialog
      ref="videoDialog"
      title="语音对讲"
      :videoLoad="videoLoad"
      @opened="videoDialogOpened"
    >
      <talk ref="talk" @openSuccess="videoLoad = true" />
    </video-dialog>
  </div>
</template>

<script>
export default {
  components: {
    initSocket: () => import("@/components/initSocket"),
    talk: () => import("./talk.vue"),
    videoDialog: () => import("@/components/videoDialog.vue"),
  },
  computed: {
    socketInfo() {
      return this.$store.state.socket.info;
    },
    token() {
      return this.$store.state.socket.token;
    },
    isLocal() {
      return this.$store.state.isLocal;
    },
  },
  watch: {
    socketInfo(info) {
      info && this.socketInfoHandler(info);
    },
  },
  data() {
    return {
      show: false,
      talkId: "",
      session: "",
      videoLoad: false,
      talkUrl: "",
    };
  },
  methods: {
    talkOpenSuccess() {},
    audioPlayer(play = true) {
      const music = document.querySelector(".alarm");
      if (play) {
        music.play();
      } else {
        music.pause();
      }
    },
    async getTalk() {
      try {
        let res = await this.$http.get(`/ws/talk`);
        if (res.code === 0) {
          this.$refs.socket.send({
            tag: "0302",
            sn: 1,
            parkingLotId: res.data,
            type: 1,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        console.log(err);
      }
    },
    beforeClose() {
      this.show = false;
    },
    socketInfoHandler(res) {
      if (res.tag === "0412") {
        this.audioPlayer();
        this.talkId = res.data.talkId;
        this.title = `语音对讲（${res.data.deviceName}）`;

        this.$confirm(`收到语音对讲请求, 是否打开?`, res.data.deviceName, {
          confirmButtonText: "打开",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.getPlayerUrl();
            this.audioPlayer(false);
          })
          .catch(() => {
            this.audioPlayer(false);
            this.$emit("refreshMessage", true);
            setTimeout(() => {
              this.$emit("refreshMessage", false);
              this.beforeClose();
            }, 1000 * 30);
          });
      }
    },
    async getLocalPlayerUrl(rtsp) {
      try {
        const res = await this.$http.get(
          `http://localhost:${process.env.VUE_APP_LOCAL_FFMPEG_PORT}?rtsp=${rtsp}`
        );
        if (res.code === 0) {
          this.$refs.videoDialog.videoUrl = res.data;
          this.$refs.videoDialog.show = true;
        } else {
          this.$message.error(res.msg);
          this.$emit("error", res.msg);
        }
      } catch {
        this.$notify({
          title: "监控播放异常",
          message: "监测到内网视频所需的NodeJS脚本未启用",
          type: "error",
          duration: 0,
        });
      }
    },
    async getPlayerUrl() {
      try {
        let res = await this.$http.post(`/proxy/talk/start`, {
          talkId: this.talkId,
          token: this.token,
          local: this.isLocal ? "是" : "否",
        });

        if (res.code === 0) {
          this.$emit("refreshMessage", false);

          this.talkUrl = res.data.talkUrl;

          if (res.data.deviceType === "百胜对讲机") {
            this.$refs.videoDialog.aliRtcAuthInfo = res.data.rtc;
            this.videoLoad = true;
            this.$refs.videoDialog.show = true;
          } else if (this.isLocal) {
            this.getLocalPlayerUrl(res.data.videoUrl);
          } else {
            this.$refs.videoDialog.videoUrl = res.data.videoUrl;
            this.$refs.videoDialog.show = true;
          }
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        console.log(err);
      }
    },

    videoDialogOpened() {
      this.$refs.talk.talkUrl = this.talkUrl;
    },
  },
};
</script>

<style lang="scss">
#messageDialog {
  .el-dialog {
    width: 60%;
  }
}
</style>
